import React from 'react';
import styled from 'styled-components';
import { useChatbot } from 'hooks/useChatbot';

const Card = styled.div`
  background: var(--gray);
  border-radius: 17px;

  .uk-card-header {
    font-family: 'Sansation Regular', sans-serif;
    background: linear-gradient(79deg, #2c75c3 30%, var(--dark-sky-blue) 93%);
    padding: 30px 20px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    text-align: center;

    h2,
    p {
      color: var(--white);
      margin: 0;
    }

    p {
      font-size: 24px;
      line-height: 2rem;
      width: 50%;
      margin: 0 auto;
    }

    .percent {
      font-size: 72px;
    }
  }
  .uk-card-body {
    padding: 35px;
    color: var(--black);
    h4 {
      font-size: 28px;
      margin: 0;
      margin-bottom: 1.25rem;
    }

    ol {
      font-size: 20px;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .uk-card-footer {
    padding: 0 35px 40px;
    border: 0;
    button {
      padding: 20px 44px;
    }
  }
`;

export const PricingCard = ({
  rate,
  rate_text1,
  rate_text2,
  heading,
  content,
}) => {
  const { openChatbot } = useChatbot();
  return (
    <div>
      <Card className="uk-card uk-card-default uk-box-shadow-hover-large">
        <div className="uk-card-header">
          <h2 className="percent">{rate}</h2>
          <p>{rate_text1}</p>
          <p>{rate_text2}</p>
        </div>
        <div className="uk-card-body">
          <h4>{heading.text}</h4>
          <div dangerouslySetInnerHTML={{ __html: content.html }} />
        </div>
        <div className="uk-card-footer">
          <button className="uk-button uk-width-1-1" onClick={openChatbot}>
            Talk to Payne Bot
          </button>
        </div>
      </Card>
    </div>
  );
};
