import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components';
import { useChatbot } from 'hooks/useChatbot';

import BannerPaynebot from 'images/img-banner-paynebot.png';

const StyledWorkWithUs = styled.div`
  background: linear-gradient(
    90deg,
    rgba(66, 161, 219, 1) 0%,
    rgba(19, 180, 198, 1) 100%
  );

  @media screen and (min-width: 960px) {
    .robot-bg {
      background: url(${BannerPaynebot}) right top no-repeat;
      background-size: 32%;
      background-position-x: 80%;
    }
  }

  .subtitle {
    color: var(--white);
    font-family: 'InterUI Regular', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: 1.18px;
  }

  .cta {
    position: absolute;
    right: 90px;
    bottom: 75px;
  }

  .lets-recruit-button {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b0f15;
    border-radius: 6px;
    box-shadow: 0 21px 16px 0 rgba(1, 2, 2, 0.27);
    background-color: #4febf8;
    padding: 22px 38px;
  }

  .lets-recruit-button:hover {
    text-decoration: none;
  }
`;

export const WorkWithUs = ({ subtitle, title, text, button_text }) => {
  const { openChatbot } = useChatbot();
  return (
    <StyledWorkWithUs>
      <div
        className="uk-container uk-padding-large robot-bg"
        uk-scrollspy="cls:uk-animation-fade"
      >
        <div className="uk-child-width-1-2@m uk-grid-match" uk-grid="true">
          <div className="uk-width-1-1">
            <span className="subtitle">{subtitle}</span>
          </div>
          <div>
            <div className="uk-grid-small uk-light" uk-grid="true">
              <div className="uk-width-expand">
                <SectionTitle title={title} dark compact />
                <p className="uk-visible@m uk-light uk-padding-large uk-padding-remove-top">
                  {text}
                </p>
                <div className="uk-hidden@m">
                  <p className="uk-light">{text}</p>
                </div>
                <div className="uk-margin-xlarge-top uk-hidden@m"></div>
                <div className="uk-margin-large-top uk-hidden@m">
                  <button
                    className="lets-recruit-button uk-button"
                    onClick={openChatbot}
                  >
                    {button_text}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-expand@m uk-width-1-1">
            <div className="uk-inline">
              <div className="cta uk-visible@m">
                <button
                  className="lets-recruit-button uk-button"
                  onClick={openChatbot}
                >
                  {button_text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledWorkWithUs>
  );
};
