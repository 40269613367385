import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components';
import Img from 'gatsby-image';

const StyledClients = styled.div`
  background: var(--white);
`;

export const OurClients = ({ title, text, clients }) => (
  <StyledClients>
    <div
      className="uk-container uk-padding-large"
      uk-scrollspy="cls:uk-animation-fade"
    >
      <div uk-grid="true">
        <div className="uk-width-1-1">
          <div className="uk-grid-small" uk-grid="true">
            <SectionTitle bulletColor="blue" title={title}>
              <div className="uk-width-1-3@m uk-width-1-1">
                <p className="muted">{text}</p>
              </div>
            </SectionTitle>
          </div>
        </div>
      </div>
      <div
        className="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2@m uk-text-center uk-grid-medium"
        uk-grid="true"
      >
        {clients.map(({ client_logo: image }, i) => (
          <div key={i}>
            <Img fixed={image.localFile.childImageSharp.fixed} />
          </div>
        ))}
      </div>
    </div>
  </StyledClients>
);
