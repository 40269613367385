import React from 'react';
import { Link } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Header, Footer, SEO } from 'components';
import { useChatbot } from 'hooks/useChatbot';

import 'styles/theme.scss';
import 'styles/fonts.scss';

const GlobalStyle = createGlobalStyle`

  :root {
    --main-color: #42a1db;
    --main-color-light: #d1eeff;
    --main-color-hovered: #2c75c3;
    --light-color-hovered: #f4f4f4;
    --light-blue: #ebf5fa;
    --dark-blue: #122348;
    --dark-sky-blue: #42a1db;
    --black-blue: #0f1343;
    --black: #000000;
    --white: #ffffff;
    --gray: #f8f8f8;
    --faded-black: #1e1e1e;
    --cookiebg: #ffedce;
    --muted: #979ea6;
    --twitter: #1084e4;
    --instagram: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    --linkedin: #0077b5;
    --facebook: #3b5997;
    --blue-tag: #28b6e9;
  }
    
  .cookie-banner {
    position: fixed;
    background-color: var(--cookiebg);
    left: 0;
    margin: 20px;
    border-radius: 20px;
    color: var(--black);
    padding: 50px;
    width: calc(100vw - 140px);
    z-index: 99999;
    text-align: center;

    @media screen and (min-width: 1025px) {
      h4{
        padding: 0 160px;
      }
    }
    
  }

  html, body {
    font-family: 'InterUI Regular', sans-serif;
    font-size: 16px;
    line-height: 1.81;
    color: var(--black);
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Sansation Bold', sans-serif;
    color: var(--black);
  }

  p, em {
    color: var(--black);
    line-height: 1.81;
  }

  a {
    color: var(--main-color);
    &:hover {
      color: var(--main-color-hovered);
    }
  }

  blockquote {
    font-family: 'InterUI Medium', sans-serif;

    p {
      line-height: 1.42;
    }

  }

  label {
    font-family: 'InterUI Regular', sans-serif;
    color: var(--faded-black);
    font-size: 12px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .uk-navbar-container {
    height: 148px !important;
  }

  .uk-button {
    font-family: 'InterUI Medium', sans-serif;
    font-size: 18px;
    padding: 8px 35px;
    border-radius: 6px;
    color: var(--white);
    background-color: var(--main-color);

    &.navigation {
      font-family: 'Sansation Regular', sans-serif;
    }

    &.small {
      font-size: 16px;
      padding: 2px 16px;

      &.secondary {
        border: 1px solid var(--dark-blue);
        box-shadow: none;
      }
      
    }

    &:hover {
      background-color: var(--main-color-hovered);
      color: var(--white);
    }

    &.secondary {
      background-color: var(--white);
      color: var(--dark-blue);
      box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);

      &:hover {
        background-color: var(--light-color-hovered);
      }
    }
  }
  
`;

export const Layout = ({
  showTags = true,
  chatModuleId = '',
  children,
  chatOpen = false,
  isBlog = false,
}) => {
  const [ready, setReady] = React.useState(false);
  const { initChatbot, openChatbot } = useChatbot();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const uikit = require('uikit');
      const icons = require('uikit/dist/js/uikit-icons.min');
      uikit.use(icons);
      setReady(true);

      // init chat bot
      setTimeout(() => {
        initChatbot(chatModuleId, chatOpen);
      }, 1000);
    }
  }, []);

  React.useEffect(() => {}, []);

  return (
    ready && (
      <>
        <SEO isBlog={isBlog} />
        <GlobalStyle />
        <Header />
        {children}
        <Footer showTags={showTags} />
        <CookieConsent
          disableStyles={true}
          containerClasses="cookie-banner uk-box-shadow-hover-large"
          buttonClasses="uk-button uk-button-default secondary small navigation"
          buttonText="I'm OK with that"
        >
          <h4 className="uk-margin-bottom">
            We use cookies and similar technologies for performance, analytics,
            and to help our site function. Read our{' '}
            <Link to={'/privacy-policy'}>Privacy Policy</Link> if you would like
            to know more.
          </h4>
        </CookieConsent>
      </>
    )
  );
};
