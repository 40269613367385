import React from 'react';
import styled from 'styled-components';

const StyledNewsletter = styled.div`
  background: var(--gray);
  margin-top: 102px;

  h2 {
    margin: 0 0 30px 0;
  }

  .newsletter-input {
    width: 360px;
    margin-bottom: 18px;
  }

  .subscribe-button {
    font-family: 'InterUI Medium', sans-serif;
    background: var(--main-color);
    color: var(--white);
    font-size: 18px;
    padding: 18px 32px;
    border-radius: 6px;
    height: 70px;
    width: 315px;
  }
`;

export const NewsletterSection = ({ title, newsletter_url, button_text }) => (
  <StyledNewsletter>
    <div className="uk-container uk-padding-large uk-text-center">
      <div className="uk-text-center">
        <h2>{title}</h2>
        <a
          href={newsletter_url}
          target="_blank"
          className="uk-button subscribe-button"
        >
          {button_text}
        </a>
      </div>
    </div>
  </StyledNewsletter>
);
