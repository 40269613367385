import React from 'react';
import styled from 'styled-components';

const StyledTodo = styled.div`
  background: purple;
  color: white;
  margin: 10px 0;
`;

export const Todo = ({ title }) => (
  <StyledTodo className="uk-width-1-1 uk-padding">
    TODO: {title} component
  </StyledTodo>
);
