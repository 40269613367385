import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components';
import Img from 'gatsby-image';
import LinkedInSVG from 'images/linkedin.svg';

const StyledOurTeam = styled.div`
  background: var(--gray);

  a {
    text-decoration: none;
    color: var(--black);
  }

  .team-item-card {
    background: var(--white);
    border-radius: 15px;

    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    h5 {
      font-family: 'Sansation Bold', sans-serif;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      margin: 0;
    }

    .status {
      font-family: 'InterUI Medium', sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: 1.18px;
      text-transform: uppercase;
    }
    .uk-card-body {
      position: relative;
      padding: 13px 17px;
      border-radius: 15px;

      .linked-in {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 10001;
        width: 30px;
      }
    }
    .uk-card-media-top {
      position: relative;
      .linked-in {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 10001;
      }
    }
  }
`;

const Member = ({
  member_image,
  member_title,
  member_type,
  member_linked_in,
}) => (
  <div className="uk-card team-item-card uk-box-shadow-hover-medium">
    <div className="uk-card-media-top">
      {member_linked_in.url && (
        <img src={LinkedInSVG} svg="true" className="linked-in" />
      )}
      <Img fluid={member_image.localFile.childImageSharp.fluid} />
    </div>
    <div className="uk-card-body">
      {member_linked_in.url && (
        <img src={LinkedInSVG} svg="true" className="linked-in" />
      )}
      <h5>{member_title.text}</h5>
      <span className="status">{member_type}</span>
    </div>
  </div>
);

export const OurTeam = ({ title, team }) => (
  <StyledOurTeam>
    <div
      className="uk-container uk-padding-large"
      uk-scrollspy="cls:uk-animation-fade"
    >
      <div
        className="uk-child-width-1-4@m uk-child-width-1-2@s uk-child-width-1-1 uk-grid-medium uk-grid-match"
        uk-grid="true"
      >
        <div className="uk-width-1-1">
          <div className="uk-grid-small" uk-grid="true">
            <SectionTitle bulletColor="blue" title={title} size="medium" />
          </div>
        </div>
        {team.map((member, i) =>
          member.member_linked_in.url ? (
            <div key={i}>
              <a href={member.member_linked_in.url} target="_blank">
                <Member {...member} />
              </a>
            </div>
          ) : (
            <div key={i}>
              <Member {...member} />
            </div>
          )
        )}
      </div>
    </div>
  </StyledOurTeam>
);
