import React from 'react';
import { Link } from 'gatsby';

export const Button = ({
  externalLink = false,
  variant = 'primary',
  size = 'medium',
  type = 'regular',
  to,
  text,
}) => {
  return externalLink ? (
    <a
      href={to}
      target="_blank"
      className={`uk-button uk-width-1-1@xs uk-width-auto@s ${variant} ${size} ${type}`}
    >
      {text}
    </a>
  ) : (
    <Link
      to={to}
      className={`uk-button uk-width-1-1@xs uk-width-auto@s ${variant} ${size} ${type}`}
    >
      {text}
    </Link>
  );
};
