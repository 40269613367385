import React from 'react';

import BulletBlue from 'images/bullet-blue.svg';
import BulletWhite from 'images/bullet-white.svg';

export const Bullet = ({ color = 'white' }) => (
  <div className="uk-width-auto">
    <img
      className="uk-margin-right uk-responsive"
      width="36"
      height="36"
      src={color === 'white' ? BulletWhite : BulletBlue}
      svg="true"
      alt="bullet"
    />
  </div>
);
