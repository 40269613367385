import React from 'react';
import styled from 'styled-components';
import PlayIcon from 'images/play-icon.png';

const Card = styled.div`
  background: var(--gray);
  border-radius: 17px;

  .uk-card-media-top {
    position: relative;
    min-height: 100px;
    background: var(--light-blue);
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;

    img {
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;
    }

    button {
      position: absolute;
      top: calc(50% - 32px);
      left: calc(50% - 32px);
      width: 64px;
      height: 64px;
      border: 0;
      border-radius: 64px;
      box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;

      img {
        margin-left: 4px;
        border-radius: 0;
        width: 17px;
        height: 24px;
      }

      :hover {
        cursor: pointer;
      }
    }
  }

  .uk-card-body {
    padding: 24px;
    color: var(--black);
    h4 {
      font-size: 24px;
    }

    h4,
    p {
      margin: 0;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const VideoCard = ({
  job_video_image,
  youtube_id,
  job_title,
  company,
  location,
}) => {
  return (
    <div uk-lightbox="true">
      <Card className="uk-card uk-card-default uk-box-shadow-hover-large">
        <a
          href={`https://www.youtube.com/watch?v=${youtube_id}`}
          data-attrs="width: 1280; height: 720;"
        >
          <div className="uk-card-media-top">
            <img src={job_video_image.localFile.childImageSharp.fixed.src} />
            <button>
              <img src={PlayIcon} />
            </button>
          </div>
          <div className="uk-card-body">
            <h4>{job_title}</h4>
            <p>
              <strong>{company}</strong>
            </p>
            <p>{location}</p>
          </div>
        </a>
      </Card>
    </div>
  );
};
