import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const SEO = ({ isBlog = false }) => {
  const {
    site: {
      siteMetadata: { title, description },
    },

    prismicSiteOptions: {
      data: { seo_title, seo_description, seo_image },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        prismicSiteOptions {
          data {
            seo_title
            seo_description
            seo_image {
              url
            }
          }
        }
      }
    `
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <html lang="en" />
        <meta charset="utf-8" />
        <script src="https://me.botxo.co/sdk/webchat.js" />
        <title>{title}</title>
        <meta name="description" content={seo_description} />
      </Helmet>

      {!isBlog && (
        <Helmet>
          <meta property="og:type" content="article" />
          <meta property="og:title" content={seo_title} />
          <meta property="og:description" content={seo_description} />
          <meta property="og:image" content={seo_image.url} />

          <meta name="twitter:image" content={seo_image.url} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={seo_title} />
          <meta name="twitter:description" content={seo_description} />
        </Helmet>
      )}
    </>
  );
};
