import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledTagFlat = styled.div`
  padding: 7px 12px;
  font-family: 'Sansation Bold', sans-serif;
  font-size: 13px;
  letter-spacing: 1.36px;
  background: var(--light-blue);
  color: var(--black);
  margin-right: 9px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const StyledTagAlt = styled.div`
  font-family: 'Sansation Regular', sans-serif;
  letter-spacing: 1.36px;
  text-transform: uppercase;
  font-size: 15px;
  background: var(--blue-tag);
  margin-right: 9px;
  margin-bottom: 10px;
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18), 0 1px 2px 0 rgba(0, 0, 0, 0.04),
    0 2px 6px 0 rgba(0, 0, 0, 0.04), inset 0 1px 0 0 rgba(225, 225, 225, 0.5);
  &:hover,
  &.active {
    background: var(--dark-blue);
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Tag = ({
  children,
  style = 'flat',
  active,
  clear = false,
  page,
}) => {
  const TagComponent = style === 'flat' ? StyledTagFlat : StyledTagAlt;
  const url = clear
    ? `/${page}/`
    : `/${page}?filter=${children.replace(/-|\s/g, '+')}`;
  return (
    <StyledLink to={url}>
      <TagComponent className={active ? 'active' : ''}>{children}</TagComponent>
    </StyledLink>
  );
};
