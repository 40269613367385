import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components';

const StyledJoinOurNetwork = styled.div`
  background: var(--gray);

  .client img {
    height: 108px;
  }
`;

export const JoinOurNetwork = ({ title, button_text, url }) => (
  <StyledJoinOurNetwork>
    <div
      className="uk-container uk-padding-large "
      uk-scrollspy="cls:uk-animation-fade"
    >
      <SectionTitle
        bulletColor="blue"
        title={title}
        button={button_text}
        to={url}
        externalLink={true}
        buttonType="navigation"
        size="small"
      />
    </div>
  </StyledJoinOurNetwork>
);
