import React from 'react';
import { SectionTitle } from 'components';

export const CtaSection = ({ title, button_text, url }) => (
  <div
    className="uk-container uk-padding-large"
    uk-scrollspy="cls:uk-animation-fade"
  >
    <SectionTitle
      title={title}
      bulletColor="blue"
      button={button_text}
      to={url}
      externalLink={true}
      size="small"
    />
  </div>
);
