import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { StaticQuery, graphql, Link } from 'gatsby';
import { useNavigation } from 'hooks/useNavigation';

import FooterLogo from 'images/logo-footer.svg';

const TagsSection = styled.div`
  h4 {
    font-size: 24px;
    line-height: 1.46;
  }
`;

const SocialLink = ({ icon, url }) => (
  <div className="uk-inline">
    <a href={url} target="_blank">
      <div className={`uk-flex uk-flex-center uk-flex-middle`}>
        <img src={icon.url} svg="true" />
      </div>
    </a>
  </div>
);

const FooterSection = styled.footer`
  background: var(--dark-blue);

  .copyright {
    color: var(--muted);
    font-size: 14px;
  }

  .uk-button.small.secondary {
    margin-bottom: 1rem;
    color: var(--dark-sky-blue);
    background: none;
    border: 1px solid var(--dark-sky-blue);
    &:hover {
      color: white;
      border-color: white;
    }
  }

  .footer-nav {
    a {
      font-size: 0.875rem;
      color: var(--dark-sky-blue);
      margin-right: 0.75rem;
      :hover {
        color: white;
        text-decoration: none;
      }
    }
  }

  .subscribe-form {
    input {
      border: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: var(--black);
      padding: 12px 15px;
      height: 48px;
      width: 230px;
      background: white;
      font-size: 14px;
      box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.12),
        inset 0 1px 3px 0 rgba(0, 0, 0, 0.06);

      &::placeholder {
        color: var(--muted);
      }
      &:focus {
        background-color: var(--gray);
        color: var(--black);
      }
    }

    button {
      border: none;
      background: var(--main-color);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 48px;
      padding: 5px 15px;
      font-size: 14px;
    }
  }

  .icon {
    width: 25px;
    height: 25px;
    color: var(--white);
    border-radius: 3px;
  }

  .twitter {
    background: var(--twitter);
  }

  .linkedin {
    background: var(--linkedin);
  }

  .facebook {
    background: var(--facebook);
  }

  .instagram {
    background: var(--instagram);
  }
`;

export const Footer = ({ showTags = true }) => {
  const { FOOTER } = useNavigation();

  return (
    <StaticQuery
      query={graphql`
        {
          prismicJobsPage {
            data {
              career_portal_url {
                url
              }
              career_portal_apply_url {
                url
              }
            }
          }
          prismicContactPage {
            data {
              social_media {
                social_id
                social_url {
                  url
                }
                footer_icon {
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { social_media: social } = data.prismicContactPage.data;
        const {
          career_portal_url,
          career_portal_apply_url,
        } = data.prismicJobsPage.data;

        const s = social.filter((o) => !!o.footer_icon);

        return (
          <>
            <FooterSection className="uk-light">
              <div className="uk-container uk-container-medium uk-padding-large">
                <div
                  className="uk-grid-small uk-flex uk-flex-top uk-text-center"
                  uk-grid="true"
                >
                  <div className="uk-width-1-1 uk-width-1-5@m">
                    <Button
                      externalLink={true}
                      to={career_portal_apply_url.url}
                      variant="secondary"
                      size="small"
                      type="navigation"
                      text={'Upload CV'}
                    />
                    <div>
                      {s.map(({ social_url, footer_icon }, i) => (
                        <SocialLink
                          icon={footer_icon}
                          url={social_url.url}
                          key={i}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-width-3-5@m uk-text-center">
                    <img
                      width="180"
                      className="uk-responsive"
                      src={FooterLogo}
                      alt="Logo"
                    />
                    <div className="uk-margin-top footer-nav">
                      <Link to={'/'} partiallyActive={false}>
                        Home
                      </Link>
                      <Link to={'/about'}>About</Link>
                      <a href={career_portal_url.url} target="_blank">
                        Jobs
                      </a>
                      <Link to={'/videos'}>Videos</Link>
                      <Link to={'/pricing'}>Pricing</Link>
                      <Link to={'/blog'}>Blog</Link>
                      <Link to={'/contact'}>Contact</Link>
                      <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-width-1-5@m uk-text-right uk-text-center">
                    <p className="copyright">
                      All right reserved.
                      <br />© 2020 Payne Search.
                    </p>
                  </div>
                </div>
              </div>
            </FooterSection>
          </>
        );
      }}
    ></StaticQuery>
  );
};
