import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Lottie from 'lottie-web-react';

const StyledAccordion = styled.ul`
  @media screen and (min-width: 960px) {
    ul {
      padding-right: 50px;
    }
  }
`;

const StyledAccordionItem = styled.li`
  border-radius: 20px;

  padding: 0.25rem 0rem 1.25rem 0.25rem;
  margin-top: 10px !important;
  margin-left: 15px;

  @media screen and (min-width: 768px) {
    padding: 1.25rem 4.5rem 1.25rem 1.25rem;
    margin-left: 15px;
  }

  :hover {
    cursor: pointer;
  }

  .uk-accordion-title {
    font-family: 'InterUI Regular', sans-serif;
    font-size: 16px;
    h3 {
      font-family: 'Sansation Bold', sans-serif;
      font-size: 22px;
      line-height: 1.5;
      color: var(--black);
      padding-left: 4.5rem;
      @media screen and (max-width: 767px) {
        padding: 1rem;
      }
      margin-bottom: 0;
    }
    :before {
      @media screen and (max-width: 767px) {
        display: none;
      }
      float: left;
      margin: 0 2rem 0 1rem;
      padding-top: 12px;
    }
  }

  .accordion .round {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }

  .uk-accordion-content {
    margin: 0.5rem 1rem 0 1rem;
    @media screen and (min-width: 768px) {
      margin: 0.5rem 0 0 4.5rem;
    }
    font-size: 15px;
  }

  &.uk-open {
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

export const Accordion = ({ items }) => {
  const [activeImage, setActiveImage] = React.useState(0);

  return (
    <StyledAccordion
      className="uk-grid uk-grid-small uk-child-width-1-1 uk-margin-medium-top"
      uk-grid="true"
    >
      <ul
        uk-accordion="collapsible: false;"
        className="uk-accordion uk-width-1-2@m"
      >
        {items.map(({ heading, content }, i) => {
          return (
            <StyledAccordionItem
              key={i}
              className={`uk-card uk-box-shadow-hover-medium ${
                i === 0 ? 'uk-open' : ''
              }`}
            >
              <a
                href="#"
                className="uk-accordion-title"
                onClick={() => {
                  setActiveImage(i);
                }}
              >
                <h3>{heading}</h3>
              </a>
              <div className="uk-accordion-content">
                <p>{content}</p>
              </div>
            </StyledAccordionItem>
          );
        })}
      </ul>
      <div className="uk-text-center uk-width-1-3@s uk-width-1-2@m uk-visible@m">
        {items.map(({ image, lottie_json }, i) => {
          if (activeImage === i) {
            if (lottie_json?.url) {
              return (
                <Lottie
                  key={i}
                  options={{
                    path: lottie_json.url,
                    loop: false,
                    autoplay: true,
                    renderer: 'svg',
                  }}
                />
              );
            } else {
              return (
                <Img
                  fluid={image.childImageSharp.fluid}
                  key={i}
                  className="uk-animation-slide-bottom-small"
                />
              );
            }
          }
          return void 0;
        })}
      </div>
    </StyledAccordion>
  );
};
