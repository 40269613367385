import React from 'react';
import { Bullet, Button } from 'components';
import PropTypes from 'prop-types';

export const SectionTitle = ({
  children,
  title,
  textColor = 'primary',
  bulletColor = 'white',
  to,
  size = 'medium',
  externalLink = false,
  button,
  buttonVariant,
  buttonSize = 'medium',
  buttonType = 'regular',
}) => (
  <div
    className={`uk-margin-${size}-bottom uk-flex uk-flex-middle`}
    uk-grid="true"
  >
    <div className="uk-width-expand@s ">
      <div className="uk-grid-medium" uk-grid="true">
        <Bullet color={bulletColor} />
        <div className="uk-width-expand uk-padding-remove-left">
          <h2 className={textColor !== 'primary' ? 'uk-light' : ''}>{title}</h2>
        </div>
      </div>
    </div>

    {children}

    {to && (
      <div
        className="uk-width-expand@s uk-text-right"
        uk-scrollspy="cls: uk-animation-slide-right-medium; offset-top: -200;"
      >
        <Button
          externalLink={externalLink}
          to={to}
          variant={buttonVariant}
          text={button}
          size={buttonSize}
          type={buttonType}
        />
      </div>
    )}
  </div>
);

SectionTitle.propTypes = {
  dark: PropTypes.bool,
  compact: PropTypes.bool,
};
