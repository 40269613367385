import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Card = styled.div`
  border-radius: 17px;

  .uk-card-body {
    padding: 24px;
    color: var(--black);
  }

  h3 {
    font-size: 24px;
    margin: 5px 0;
  }

  .post-date {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .by {
    color: var(--muted);
    font-style: italic;
  }

  .blog-image {
    height: 200px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`;

export const BlogCard = ({ children, featuredimage, date, to, author }) => (
  <div>
    <Card className="uk-card uk-card-default uk-box-shadow-hover-large">
      <Link to={`/blog/${to}`}>
        {featuredimage && (
          <Img
            fluid={featuredimage.childImageSharp.fluid}
            className="blog-image"
          />
        )}
        <div className="uk-card-body ">
          <div className="post-date">{date}</div>
          <h3 className="uk-card-title">{children}</h3>
          <div>
            <span className="by">by</span> {author}
          </div>
        </div>
      </Link>
    </Card>
  </div>
);
