import React from 'react';
import styled from 'styled-components';
import ReactRotatingText from 'react-rotating-text';
import { shuffle } from 'lodash';

const StyledTypewriterText = styled(ReactRotatingText)`
  font-family: 'InterUI Medium', sans-serif;
  color: var(--main-color) !important;

  .react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
      infinite;
  }

  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const TypewriterText = ({ items }) => (
  <StyledTypewriterText items={shuffle(items)} pause={4000} />
);
