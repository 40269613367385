import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledAboutHeader = styled.div`
  background: var(--main-color-light);
`;

const StyledImg = styled(Img)`
  margin: 2rem 0 3rem;
  width: 352px;
`;

const StyledVideoCard = styled.div`
  @media screen and (min-width: 960px) {
    margin-left: 8rem;
  }

  .uk-card {
    border-radius: 15px;

    p:last-child {
      margin-bottom: 0;
    }

    a {
      width: 100%;
    }
  }
`;

export const AboutHeader = ({
  headerImage,
  heading,
  content,
  video,
  videoImage,
  videoText,
}) => (
  <StyledAboutHeader>
    <div
      className="uk-container uk-padding-large"
      uk-scrollspy="cls:uk-animation-fade"
    >
      <div uk-grid="true'">
        <div className="uk-width-2-5@m">
          <h1>About Us</h1>
          <h3>{heading}</h3>
          <StyledImg fluid={headerImage.childImageSharp.fluid} />
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <StyledVideoCard className="uk-width-expand@m">
          <div className="uk-card uk-card-default">
            <div className="uk-card-media-top">
              <div uk-lightbox="true">
                <a
                  class="uk-inline"
                  href={video.url}
                  data-attrs="width: 1280; height: 720;"
                >
                  <Img fluid={videoImage.childImageSharp.fluid} />
                </a>
              </div>
            </div>
            <div className="uk-card-body">
              <div dangerouslySetInnerHTML={{ __html: videoText }} />
            </div>
          </div>
        </StyledVideoCard>
      </div>
    </div>
  </StyledAboutHeader>
);
