import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Logo from 'images/logo.svg';
import { Button } from 'components';
import Lottie from 'lottie-web-react';
import { useNavigation } from 'hooks/useNavigation';

const NavBar = styled.header`
  background: var(--white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  z-index: 980;

  .uk-navbar-container {
    background: var(--white) !important;
    height: 120px;
  }

  .uk-navbar-item,
  .uk-navbar-nav > li > a {
    min-height: 20px;
    padding: 0;
    line-height: 1.5;
    margin: 0px 10px;
  }

  .uk-navbar-toggle {
    padding: 0;
  }

  .uk-navbar-nav {
    > li > a {
      font-family: 'Sansation Bold', sans-serif;
      font-size: 16px;
      color: var(--faded-black);

      &:hover {
        color: var(--main-color);
      }

      &.uk-active {
        font-family: 'Sansation Bold', sans-serif;
        color: var(--main-color);
        border-bottom: 2px solid var(--main-color);
      }

      .navigation-bar .uk-button-medium {
        padding: 3px 16px;
        border-color: var(--dark-blue);
        color: var(--dark-blue);

        &:hover {
          color: var(--white);
        }
      }
    }
  }
`;

const StyledMobileMenu = styled.div`
  padding: 48px 25px;

  button {
    padding: 32px 5px;
  }

  @media screen and (min-width: 767px) {
    padding: 48px 60px;
    button {
      padding: 32px 40px;
    }
  }

  ul {
    padding-left: 0;
    margin-top: 50px;

    li {
      list-style: none;
      font-family: 'Sansation Bold', sans-serif;
      font-size: 36px;
      line-height: 60px;
      letter-spacing: -1px;

      a {
        color: var(--black);
        &.uk-active {
          color: var(--main-color);
        }
      }
    }
  }
`;

const MainNavLink = ({ to, text, partiallyActive = true }) => (
  <li>
    <Link
      to={to}
      activeClassName={'uk-active'}
      partiallyActive={partiallyActive}
    >
      {text}
    </Link>
  </li>
);

const StyledLogo = styled.img`
  max-width: 180px;

  @media screen and (min-width: 960px) {
    max-width: 280px;
  }
`;

const StyledAnimatedLogo = styled(Lottie)`
  max-width: 240px;
  margin-left: -10px;

  @media screen and (min-width: 960px) {
    max-width: 300px;
  }
`;

const OffCanvas = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: white;
  z-index: 998;
`;

export const Header = () => {
  const { MAIN } = useNavigation();

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  return (
    <StaticQuery
      query={graphql`
        {
          prismicJobsPage {
            data {
              career_portal_url {
                url
              }
              career_portal_apply_url {
                url
              }
            }
          }
          prismicSiteOptions {
            data {
              logo_animation {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          career_portal_url,
          career_portal_apply_url,
        } = data.prismicJobsPage.data;
        const { logo_animation } = data.prismicSiteOptions.data;

        return (
          <>
            <NavBar>
              <div className="uk-container uk-padding-large uk-padding-remove-top uk-padding-remove-bottom">
                <nav className="uk-navbar uk-navbar-container" uk-navbar="true">
                  <div className="uk-navbar-left">
                    <Link to={'/'}>
                      <StyledAnimatedLogo
                        options={{
                          path: logo_animation.url,
                          loop: false,
                          autoplay: true,
                          renderer: 'svg',
                        }}
                      />
                    </Link>
                  </div>
                  <div className="uk-navbar-right">
                    <button
                      className="uk-hidden@l"
                      uk-icon="icon: menu; ratio: 2"
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                    />
                    <ul className="uk-navbar-nav uk-visible@l">
                      <MainNavLink
                        to={'/'}
                        text={'Home'}
                        partiallyActive={false}
                      />
                      <MainNavLink to={'/about'} text={'About'} />
                      <li>
                        <a href={career_portal_url.url} target="_blank">
                          Jobs
                        </a>
                      </li>
                      <MainNavLink to={'/videos'} text={'Videos'} />
                      <MainNavLink to={'/pricing'} text={'Pricing'} />
                      <MainNavLink to={'/blog'} text={'Blog'} />
                      <MainNavLink to={'/contact'} text={'Contact'} />
                    </ul>
                    <div className="uk-navbar-item uk-visible@l">
                      <Button
                        externalLink={true}
                        to={career_portal_apply_url.url}
                        variant="secondary"
                        size="small"
                        type="navigation"
                        text={'Upload CV'}
                      />
                    </div>
                  </div>
                </nav>
              </div>
            </NavBar>
            {showMobileMenu && (
              <OffCanvas>
                <StyledMobileMenu>
                  <button
                    className="uk-offcanvas-close uk-animation-slide-right uk-animation-fast"
                    type="button"
                    uk-icon="icon: close; ratio: 2"
                    onClick={() => setShowMobileMenu(false)}
                  />
                  <div className=" uk-animation-slide-left uk-animation-fast">
                    <Link to={'/'}>
                      <StyledLogo src={Logo} alt="Logo" svg="true" />
                    </Link>
                    <ul>
                      <MainNavLink
                        to={'/'}
                        text={'Home'}
                        partiallyActive={false}
                      />
                      <MainNavLink to={'/about'} text={'About'} />
                      <li>
                        <a href={career_portal_url.url} target="_blank">
                          Jobs
                        </a>
                      </li>
                      <MainNavLink to={'/videos'} text={'Videos'} />
                      <MainNavLink to={'/pricing'} text={'Pricing'} />
                      <MainNavLink to={'/blog'} text={'Blog'} />
                      <MainNavLink to={'/contact'} text={'Contact'} />
                    </ul>
                    <Button
                      externalLink={true}
                      to={career_portal_apply_url.url}
                      variant="secondary"
                      size="small"
                      type="navigation"
                      text={'Upload CV'}
                    />
                  </div>
                </StyledMobileMenu>
              </OffCanvas>
            )}
          </>
        );
      }}
    ></StaticQuery>
  );
};
