import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledSocialCard = styled.div`
  border-radius: 17px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.09),
    0 3px 6px 0 rgba(24, 26, 33, 0.06), 0 1px 0 0 rgba(27, 34, 56, 0.06);

  h3 {
    text-transform: capitalize;
  }

  .card-top {
    background: ${({ bg }) => bg && `url(${bg})`};
    background-color: var(--light-blue);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 80px;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
  }

  .uk-card-body {
    padding: 20px 24px 36px;
  }

  .uk-position-bottom-left {
    margin-left: 14px;
    margin-bottom: 16px;
  }

  .tag {
    font-family: 'InterUI Medium', sans-serif;
    color: #4a4a4a;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background: var(--white);
    padding: 5px 10px;
    border-radius: 8px;
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 14px;
  }

  ul li {
    line-height: 22px;
    font-size: 15px;
    color: var(--black);
  }
`;

export const SocialCard = ({ background, icon, title, tags, bullets, url }) => (
  <div>
    <StyledSocialCard
      className="uk-card uk-card-default uk-box-shadow-hover-large"
      bg={background}
    >
      <a href={url} target="_blank">
        <div className="card-top uk-flex uk-flex-middle uk-flex-center uk-inline ">
          <img src={icon.url} alt={title} svg="true" />
          <div className="uk-position-bottom-left">
            {tags.map((tag, i) => (
              <span className="tag" key={i}>
                {tag}
              </span>
            ))}
          </div>
        </div>
        <div className="uk-card-body">
          <h3>{title}</h3>
          <ul className="uk-list uk-list-bullet">
            {bullets.map((bullet, i) => (
              <li key={i}>{bullet}</li>
            ))}
          </ul>
        </div>
      </a>
    </StyledSocialCard>
  </div>
);
