import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledSlider = styled.div`
  background: var(--black-blue);

  .quote {
    font-family: 'Sansation Regular', sans-serif;
    color: var(--white);
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 40px;
    letter-spacing: -0.05rem;
    &::before,
    &::after {
      content: '"';
    }
  }

  .author {
    color: var(--muted);
    font-size: 13px;
  }

  .dotnav {
    margin-top: 50px;
  }

  .dotnav .uk-dotnav > * > * {
    width: 12px;
    height: 12px;
    border: 1px solid var(--main-color);
    margin-right: 10px;
  }

  .dotnav .uk-dotnav > .uk-active > * {
    background-color: var(--main-color);
  }

  .dotnav .uk-dotnav > * > :focus,
  .uk-dotnav > * > :hover {
    background-color: rgba(66, 161, 219, 0.3);
  }

  .avatar {
    height: 36px;
  }
`;

const TestimonialItem = ({
  testimonial_logo,
  testimonial_text,
  testimonial_author,
  testimonial_author_role_and_compnay,
}) => {
  return (
    <li>
      <div className="uk-child-width-1-3 " uk-grid="true">
        <div className="uk-width-1-3@s uk-padding uk-padding-remove-bottom">
          {testimonial_logo && (
            <Img
              fluid={testimonial_logo.localFile.childImageSharp.fluid}
              alt=""
            />
          )}
        </div>
        <div className="uk-width-1-2@m uk-width-1-1 uk-text-left@m uk-text-center">
          <p className="quote">{testimonial_text.text}</p>
          <div className="author">
            <div className="uk-flex uk-flex-middle uk-flex-left@m uk-flex-center">
              <div>
                <h5 className="uk-margin-remove-bottom">
                  {testimonial_author}
                </h5>
                <div>{testimonial_author_role_and_compnay}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export const Testimonials = ({ testimonials }) => (
  <StyledSlider>
    <div
      className="uk-container uk-padding-large"
      uk-scrollspy="cls:uk-animation-fade"
    >
      <div uk-slider="center: true">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabIndex="-1"
        >
          <ul className="uk-slider-items uk-child-width-1-1 uk-grid">
            {testimonials.map((testimonial, i) => (
              <TestimonialItem {...testimonial} key={i} />
            ))}
          </ul>
        </div>
        <div className="dotnav" uk-grid="true">
          <div className="uk-width-1-3@m uk-width-1-1"></div>
          <div className="uk-width-1-2@m uk-width-1-1 uk-flex uk-flex-left@m uk-flex-center">
            <ul className="uk-slider-nav uk-dotnav uk-margin"></ul>
          </div>
        </div>
      </div>
    </div>
  </StyledSlider>
);
