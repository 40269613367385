import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components';

const StyledOurValues = styled.div`
  background: linear-gradient(84deg, #42a1db 28%, #2c75c3 93%);

  .uk-subnav {
    li {
      a {
        font-family: 'Sansation Bold', sans-serif;
        font-weight: 600;
        text-transform: none;
        color: var(--white);
        font-size: 24px;
        line-height: 35px;
        opacity: 0.54;
        margin-right: 31px;
      }
      &.uk-active {
        a {
          opacity: 1;
          border-bottom: 3px solid #d1eeff;
        }
      }
    }
  }
`;

export const OurValues = ({ title, values }) => (
  <StyledOurValues>
    <div
      className="uk-container uk-padding-large"
      uk-scrollspy="cls:uk-animation-fade"
    >
      <div uk-grid="true">
        <div className="uk-width-2-5@l">
          <SectionTitle title={title} textColor="white" />
        </div>

        <div className="uk-width-expand uk-visible@m">
          <div>
            <ul
              className="uk-subnav"
              uk-switcher="animation: uk-animation-fade"
            >
              {values.map(({ value_heading }, i) => (
                <li key={i}>
                  <a href="#">{value_heading.text}</a>
                </li>
              ))}
            </ul>
            <ul className="uk-switcher uk-margin">
              {values.map(({ value_text }, i) => (
                <li key={i}>
                  <p className="uk-light">{value_text.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="uk-width-expand uk-hidden@m">
          <div>
            {values.map(({ value_heading, value_text }, i) => (
              <React.Fragment key={i}>
                <h3 className="uk-light">{value_heading.text}</h3>
                <p className="uk-light">{value_text.text}</p>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  </StyledOurValues>
);
