import React from 'react';
import { SectionTitle, Accordion } from 'components';

export const DigitalProcess = ({ title, process }) => {
  const items = process.map(
    ({ process_heading, process_text, process_animation, process_image }) => {
      return {
        heading: process_heading.text,
        content: process_text.text,
        lottie_json: process_animation,
        image: process_image.localFile,
      };
    }
  );
  return (
    <div
      className="uk-container uk-padding-large"
      uk-scrollspy="cls:uk-animation-slide-bottom-medium; offset-top: -200;"
    >
      <SectionTitle bulletColor="blue" title={title} size="medium" />
      <Accordion items={items} />
    </div>
  );
};
