import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import moment from 'moment';
import { SectionTitle, BlogCard } from 'components';

const StyledBlogSection = styled.div(
  ({ bg }) => `
    background: var(${bg === 'white' ? '--white' : '--gray'});
  `
);

const Blogs = ({ title, posts, bg, cta_text, button_text }) => (
  <StyledBlogSection bg={bg}>
    <div className="uk-container uk-padding-large">
      <SectionTitle
        title={title}
        bulletColor="blue"
        button={cta_text || button_text}
        to={'/blog'}
      />
      <div
        className="uk-child-width-1-3@m uk-grid-match"
        uk-grid="true"
        uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-medium; delay: 200; offset-top: -200;"
      >
        {posts.map(({ uid: slug, data: { date, author, title, image } }, i) => (
          <BlogCard
            key={i}
            featuredimage={image.localFile}
            date={moment(date).format('DD MMM YYYY')}
            author={author}
            to={slug}
            className="uk-visible@l"
          >
            {title.text}
          </BlogCard>
        ))}
      </div>
    </div>
  </StyledBlogSection>
);

const query = graphql`
  query BlogsQuery {
    prismicHomePage {
      data {
        blog_heading {
          text
        }
        blog_button_text
      }
    }
    allPrismicBlogPost(sort: { fields: data___date, order: DESC }, limit: 3) {
      nodes {
        uid
        data {
          title {
            text
          }
          date
          author
          image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BlogSection = ({ bg = 'grey', cta_text }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Blogs
        title={data.prismicHomePage.data.blog_heading.text}
        button_text={data.prismicHomePage.data.blog_button_text}
        posts={data.allPrismicBlogPost.nodes}
        bg={bg}
        cta_text={cta_text}
      />
    )}
  />
);
