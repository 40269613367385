import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';

const StyledUploadCVSection = styled.div`
  background: var(--gray);

  h2 {
    font-size: 36px;
    margin: 0 0 14px 0;
  }

  p {
    margin-bottom: 19px;
  }
`;

export const UploadCvSection = ({ title, text, url, button_text }) => (
  <StyledUploadCVSection>
    <div className="uk-container uk-padding-large ">
      <div className="uk-text-center">
        <h2>{title}</h2>
        <p className="uk-padding-large uk-padding-remove-top uk-padding-remove-bottom">
          {text}
        </p>
        <div>
          <Button externalLink={true} to={url} text={button_text} />
        </div>
      </div>
    </div>
  </StyledUploadCVSection>
);
