export const useChatbot = () => {
  const initChatbot = (id = '', chatOpen = false) => {
    if (!window.botxoloaded) {
      window.initBotXOChat({
        id: 'e9a3837d-9ed4-4878-9ca7-344326aeb64a',
        mode: 'clear_past_conversations',
        buttonLogoClose: 'https://paynesearch.com/chatbot3.png',
        buttonLogoOpen: 'https://paynesearch.com/chatbot1.png',
        buttonWidth: 100,
        buttonHeight: 100,
        bottom: 25,
        right: 25,
        ref: id,
        autoloadDelay: chatOpen ? '3' : null,
      });
    }

    window.botxoloaded = true;
  };

  const openChatbot = () => {
    document.getElementById('botxo-button-').click();
  };

  return { initChatbot, openChatbot };
};
